// import { CompanyUserRole } from '@neo1/client';
import { isCompanyAdmin } from '@neo1/client/lib/entities/user/utils';
import {
  RouteContext,
  hasEitherSpendOrTravel,
  hasSpendManagement,
} from 'config/routes/utils';
import wrapAsync from 'utils/wrapAsync';
import {
  ROUTE_NAME_SETTINGS_EXPENSE,
  ROUTE_NAME_SETTINGS_EXPENSE_FINANCE,
  ROUTE_NAME_SETTINGS_EXPENSE_MILEAGE,
  ROUTE_NAME_SETTINGS_EXPENSE_SPEND_POLICIES,
  ROUTE_NAME_SETTINGS_EXPENSE_TAXES,
  ROUTE_NAME_SETTINGS_EXPENSE_TYPES,
  ROUTE_NAME_SETTINGS_GENERAL,
  ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO,
  ROUTE_NAME_SETTINGS_GENERAL_PAYMENT_METHODS,
  ROUTE_NAME_SETTINGS_GENERAL_USERS,
  ROUTE_PATH_SETTINGS_EXPENSE,
  ROUTE_PATH_SETTINGS_EXPENSE_FINANCE,
  ROUTE_PATH_SETTINGS_EXPENSE_MILEAGE,
  ROUTE_PATH_SETTINGS_EXPENSE_SPEND_POLICIES,
  ROUTE_PATH_SETTINGS_EXPENSE_TAXES,
  ROUTE_PATH_SETTINGS_EXPENSE_TYPES,
  ROUTE_PATH_SETTINGS_GENERAL,
  ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO,
  ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_METHODS,
  ROUTE_PATH_SETTINGS_GENERAL_USERS,
} from './constants';
import T from './messages';

export const settingsGeneralRoute = {
  path: ROUTE_PATH_SETTINGS_GENERAL,
  name: ROUTE_NAME_SETTINGS_GENERAL,
  children: [
    {
      title: T.myCompanyPage,
      exact: true,
      path: ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO,
      name: ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "settingsGeneralBusinessInfo" */
            './General'
          ),
      ),
    },
    {
      title: T.users,
      path: ROUTE_PATH_SETTINGS_GENERAL_USERS,
      name: ROUTE_NAME_SETTINGS_GENERAL_USERS,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "settingsGeneralUsers" */
            './Users'
          ),
      ),
    },
    {
      title: T.paymentMethodsPage,
      show: hasEitherSpendOrTravel,
      path: ROUTE_PATH_SETTINGS_GENERAL_PAYMENT_METHODS,
      name: ROUTE_NAME_SETTINGS_GENERAL_PAYMENT_METHODS,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "settingsGeneralPaymentMethods" */
            './PaymentMethods'
          ),
      ),
    },
  ],
  show: ({ user }: RouteContext) => isCompanyAdmin(user),
};

export const settingsExpenseRoute = {
  path: ROUTE_PATH_SETTINGS_EXPENSE,
  name: ROUTE_NAME_SETTINGS_EXPENSE,
  children: [
    {
      title: T.accountingPage,
      show: hasSpendManagement,
      path: ROUTE_PATH_SETTINGS_EXPENSE_FINANCE,
      name: ROUTE_NAME_SETTINGS_EXPENSE_FINANCE,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "settingsExpenseFinance" */
            './Accounting'
          ),
      ),
    },
    {
      title: T.taxesPage,
      show: hasSpendManagement,
      exact: true,
      path: ROUTE_PATH_SETTINGS_EXPENSE_TAXES,
      name: ROUTE_NAME_SETTINGS_EXPENSE_TAXES,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "settingsExpenseTaxes" */
            './Taxes'
          ),
      ),
    },

    {
      title: T.expenseTypesPage,
      show: hasSpendManagement,
      exact: true,
      path: ROUTE_PATH_SETTINGS_EXPENSE_TYPES,
      name: ROUTE_NAME_SETTINGS_EXPENSE_TYPES,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "settingExpenseTypes" */
            './ExpenseTypes'
          ),
      ),
    },
    {
      title: T.spendPoliciesPage,
      show: hasSpendManagement,
      exact: true,
      path: ROUTE_PATH_SETTINGS_EXPENSE_SPEND_POLICIES,
      name: ROUTE_NAME_SETTINGS_EXPENSE_SPEND_POLICIES,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "settingExpenseSpendPolicies" */
            './SpendPolicies'
          ),
      ),
    },
    {
      title: T.mileagePage,
      show: hasSpendManagement,
      exact: true,
      path: ROUTE_PATH_SETTINGS_EXPENSE_MILEAGE,
      name: ROUTE_NAME_SETTINGS_EXPENSE_MILEAGE,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "settingsExpenseMileage" */
            './Mileage'
          ),
      ),
    },
  ],
  show: ({ user }: RouteContext) => isCompanyAdmin(user),
};
