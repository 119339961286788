import {
  CreateCompanyUserParams,
  CreateDomainUserParams,
  CreateSystemUserParams,
  CreateUserParams,
  UpdateCompanyUserParams,
  UpdateDomainUserParams,
  UpdatePasswordParams,
  UpdateSystemUserParams,
  UpdateUserParams,
  User,
} from '@neo1/client/lib/entities/user/types';
import * as rpcBatchUtils from '@neo1/client/lib/rpc/batch';
import { getClient } from '@neo1/client/lib/rpc/client';
import { RpcCommand } from '../../rpc';
import { rolesContainsAdminRole, normalizeUser } from './utils';
import { UserLevel } from './constants';

export const getCreateSystemUserPayload = (params: CreateSystemUserParams) => ({
  email: params.email,
  fullname: params.displayName,
});
export const getCreateDomainUserPayload = (params: CreateDomainUserParams) => ({
  domain_id: params.domainId,
  email: params.email,
  fullname: params.displayName,
  additional_domain_ids: params.additionalDomains,
});
export const getCreateCompanyUserPayload = (
  params: CreateCompanyUserParams,
) => ({
  company_id: params.companyId,
  email: params.email,
  first_name: params.firstName,
  last_name: params.lastName,
  phone: params.phone,
  external_id: params.externalId,
  locale: params.locale,
  roles: params.roles,
  supervisor_id: params.supervisorId,
  preferred_currency: params.preferredCurrency,
  title: params.title,
  traveler_group: params.travelerGroup,
  birth_date: params.birthDate,
});

/**
 * Updates given user
 * @param params
 */
export function createUser(params: CreateUserParams) {
  let command: RpcCommand;

  switch (params.level) {
    case UserLevel.SYSTEM:
      command = {
        method: 'new_system_user',
        params: getCreateSystemUserPayload(params),
      };
      break;
    case UserLevel.DOMAIN:
      params;
      command = {
        method: 'new_domain_user',
        params: getCreateDomainUserPayload(params),
      };
      break;
    case UserLevel.COMPANY:
      command = {
        method: 'new_company_user',
        params: getCreateCompanyUserPayload(params),
      };

      break;
    default:
      throw `Could not create user without level`;
  }

  return getClient().sendCommand(command).then(normalizeUser);
}

function getUpdateDomainUserAdditionalDomainsPayload({
  additionalDomains,
}: UpdateDomainUserParams) {
  return {
    operation: 'set_domain_user_additional_domains',
    additional_domain_ids: additionalDomains,
  };
}

function getUpdateSystemUserDetailsPayload(params: UpdateSystemUserParams) {
  return {
    operation: 'update_system_user',
    email: params.email,
    full_name: params.displayName,
  };
}

function getUpdateDomainUserDetailsPayload(params: UpdateDomainUserParams) {
  return {
    operation: 'update_domain_user',
    email: params.email,
    full_name: params.displayName,
  };
}

function getUpdateCompanyUserDetailsPayloaduser(
  params: UpdateCompanyUserParams,
) {
  return {
    operation: 'update_company_user',
    email: params.email,
    first_name: params.firstName,
    last_name: params.lastName,
    preferred_currency: params.preferredCurrency,
    locale: params.locale,
    title: params.title,
    phone: params.phone,
    birth_date: params.birthDate || null,
    traveler_group: params.travelerGroup,
  };
}

function getUpdateUserStatusPayload({ status }: UpdateUserParams) {
  return {
    operation: 'update_status',
    status,
  };
}

function getUpdateCompanyUserExternalIdPayload({
  externalId = null,
}: UpdateCompanyUserParams) {
  return {
    operation: 'set_user_external_id',
    external_id: externalId,
  };
}

function getUpdateCompanyUserSupervisorPayload(
  params: UpdateCompanyUserParams,
) {
  return {
    operation: 'set_supervisor',
    supervisor_id: params.supervisorId || null,
  };
}

function getUpdateCompanyUserRolesPayloaduser({
  roles = [],
}: UpdateCompanyUserParams) {
  return {
    operation: 'set_company_roles',
    roles,
  };
}

export function getUpdateUserPayload(
  params: UpdateUserParams,
  applyAll: boolean,
) {
  const updates = [];

  switch (params.level) {
    case UserLevel.SYSTEM:
      updates.push(
        getUpdateSystemUserDetailsPayload(params),
        getUpdateUserStatusPayload(params),
      );
      break;

    case UserLevel.DOMAIN:
      updates.push(
        getUpdateDomainUserDetailsPayload(params),
        getUpdateUserStatusPayload(params),
      );
      if (applyAll) {
        updates.push(getUpdateDomainUserAdditionalDomainsPayload(params));
      }
      break;

    case UserLevel.COMPANY:
      updates.push(getUpdateCompanyUserDetailsPayloaduser(params));
      if (rolesContainsAdminRole(params.roles) || applyAll) {
        updates.push(
          getUpdateCompanyUserSupervisorPayload(params),
          getUpdateCompanyUserExternalIdPayload(params),
          getUpdateCompanyUserRolesPayloaduser(params),
          getUpdateUserStatusPayload(params),
        );
      }
      break;

    default:
      throw new Error(
        'error in function getUpdateUserPayload : cannot update a user without a level',
      );
  }

  return {
    user_id: params.id,
    updates,
  };
}

/**
 * Updates given user
 * @param user
 * @param applyAllOperations
 */
export function updateUser(user: UpdateUserParams, applyAll = false) {
  const params = getUpdateUserPayload(user, applyAll);
  return getClient()
    .sendCommand({ method: 'update_user', params })
    .then(normalizeUser);
}

/**
 * Updates one user password
 * @param payload
 */
export function updateUserPassword(payload: UpdatePasswordParams) {
  return getClient().sendCommand({
    method: 'update_user_password',
    params: {
      user_id: payload.id,
      password: payload.password,
    },
  });
}

/**
 * Retrieves information about one user by its user_id
 * @param user_id
 */
export function fetchUserById(userId: string) {
  return getClient()
    .sendCommand({ method: 'get_user_by_id', params: { user_id: userId } })
    .then(normalizeUser);
}

/**
 * Retrieves information about several users with a list of userIds
 * @param userIds[]
 */
export function fetchUsersByIds(userIds: string[] = []) {
  if (!Array.isArray(userIds) || userIds.length === 0) {
    return Promise.resolve([]);
  }
  return getClient()
    .batchCommands(
      userIds.map((userId) => ({
        method: 'get_user_by_id',
        params: { user_id: userId },
      })),
    )
    .then((results) => rpcBatchUtils.unwrapFilter(results, normalizeUser));
}

/**
 * Fetches the whole list of domain users for a given domain
 * @returns {Promise.<Array>}
 */
export function fetchSystemUsers(): Promise<User[]> {
  return getClient()
    .sendCommand({ method: 'get_system_users', params: {} })
    .then((users) => users.map(normalizeUser));
}

export async function acceptEula({ id }: User, version: string) {
  try {
    const result = await getClient().sendCommand({
      method: 'accept_eula',
      params: { user_id: id, version },
    });
    return normalizeUser(result);
  } catch (error) {
    throw error;
  }
}

/**
 * Deletes the given user
 * @param user_id
 */
export async function deleteUser(user_id: User['id']): Promise<any> {
  try {
    const result = await getClient().sendCommand({
      method: 'delete_user',
      params: { user_id },
    });
    return result;
  } catch (error) {
    throw error;
  }
}
