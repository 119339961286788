import classNames from 'classnames';
import InfoBox from 'components/elements/InfoBox';
import SmallLoader from 'components/elements/Loader/SmallLoader';
import { DeviceSize, useWindowContext } from 'contexts/window';
import { ReactNode, RefObject, useEffect } from 'react';
import TabsRoute from '../TabsRoute';
import FloatingCtaButton from './FloatingCtaButton';
import Header, { pageHeaderId } from './Header';
import styles from './Page.module.css';
import Subheader from './Subheader';
import { Cta, Dropdown, Filter } from './types';

export const pageContentDataTestId = 'pageContent';
export const mainContentId = 'mainContent';

export type Props = {
  title?: string;
  subTitle?: string;
  isLoading?: boolean;
  isHeaderDark?: boolean;
  loadingMessage?: string;
  onExit?: () => void;
  information?: ReactNode;
  isInformationClosable?: boolean;
  renderHeaderActions?: () => ReactNode;
  renderPageActions?: () => ReactNode;
  renderPagePrecontent?: () => ReactNode;
  mainCta?: Cta;
  secondCta?: Cta;
  thirdCta?: Cta;
  dropdown?: Dropdown;
  mainFilter?: Filter;
  className?: string;
  headerClassName?: string;
  tabClassName?: string;
  precontentClassName?: string;
  subheaderClassName?: string;
  contentClassName?: string;
  contentRef?: RefObject<HTMLDivElement>;
  children?: ReactNode;
  withTopMarginContent?: boolean;
  hideNav?: boolean;
};

const Page = ({
  className,
  headerClassName,
  tabClassName,
  precontentClassName,
  subheaderClassName,
  contentClassName,
  children,
  isLoading,
  isHeaderDark,
  onExit,
  mainCta,
  secondCta,
  thirdCta,
  dropdown,
  mainFilter,
  renderHeaderActions,
  renderPageActions,
  renderPagePrecontent,
  title,
  subTitle,
  loadingMessage,
  contentRef,
  information,
  isInformationClosable,
  withTopMarginContent,
  hideNav,
}: Props) => {
  const { deviceSize } = useWindowContext();

  useEffect(() => {
    if (title && !isLoading) {
      window.document.title = `Neo1 - ${title}`;
    }
  }, [title, isLoading]);

  const headerActions = renderHeaderActions ? renderHeaderActions() : undefined;
  const pageActions = renderPageActions ? renderPageActions() : undefined;

  return (
    <div className={classNames(styles.page, className)} data-testid="page">
      {title && (
        <Header
          title={title}
          subTitle={subTitle}
          onExit={onExit}
          actions={headerActions}
          isLoading={isLoading}
          mainCta={deviceSize === DeviceSize.tablet ? mainCta : undefined}
          mainFilter={
            deviceSize === DeviceSize.tablet && !headerActions
              ? mainFilter
              : undefined
          }
          className={headerClassName}
          isDark={isHeaderDark}
        />
      )}
      {!hideNav && (
        <div className={tabClassName}>
          <TabsRoute />
        </div>
      )}
      {!isLoading && (
        <div
          className={classNames(styles.precontent, precontentClassName)}
          data-testid="pagePrecontent"
        >
          {Boolean(information) && (
            <InfoBox
              className={styles.information}
              isClosable={isInformationClosable}
              testId="informationBanner"
            >
              {information}
            </InfoBox>
          )}

          {renderPagePrecontent && (
            <>
              {renderPagePrecontent()}
              <div className={styles.precontentSeparator} />
            </>
          )}

          <Subheader
            mainCta={deviceSize === DeviceSize.desktop ? mainCta : undefined}
            secondCta={secondCta}
            thirdCta={thirdCta}
            dropdown={dropdown}
            mainFilter={
              deviceSize !== DeviceSize.tablet || renderHeaderActions
                ? mainFilter
                : undefined
            }
            pageActions={pageActions}
            className={classNames(styles.subheader, subheaderClassName)}
          />
        </div>
      )}
      <main
        ref={contentRef}
        className={classNames('neo1AppMain', styles.content, contentClassName, {
          [styles.centered]: isLoading,
          [styles.topMargin]: withTopMarginContent,
        })}
        data-testid={pageContentDataTestId}
        aria-labelledby={pageHeaderId}
        tabIndex={-1}
        id={mainContentId}
      >
        {isLoading ? <SmallLoader label={loadingMessage} /> : children}
      </main>
      {mainCta && deviceSize === DeviceSize.mobile && (
        <FloatingCtaButton cta={mainCta} />
      )}
    </div>
  );
};

Page.defaultProps = {
  title: undefined,
  subTitle: undefined,
  isHeaderDark: false,
  isLoading: false,
  loadingMessage: 'Loading, please wait...',
  onExit: undefined,
  information: undefined,
  isInformationClosable: false,
  renderHeaderActions: undefined,
  renderPageActions: undefined,
  renderPagePrecontent: undefined,
  mainCta: undefined,
  secondCta: undefined,
  thirdCta: undefined,
  dropdown: undefined,
  mainFilter: undefined,
  className: undefined,
  headerClassName: undefined,
  tabClassName: undefined,
  precontentClassName: undefined,
  subheaderClassName: undefined,
  contentClassName: undefined,
  contentRef: undefined,
  children: undefined,
  withTopMarginContent: false,
  hideNav: false,
};

export default Page;
